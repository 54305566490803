<template>
  <div class="flex h-full items-center tabular-nums" :class="params.justifyStart ? 'justify-start' : 'justify-end'">
    {{ currencyValue }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { getCurrencySymbol, formatNumber } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const currencyValue = computed(
  () => `${getCurrencySymbol(props.params.currencyKey)}${formatNumber(props.params.value)}`
);
</script>
